$('#slider').slick({
    infinite: false,
    slidesToShow: 1,
    cssEase: 'ease-in-out',
    dots: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
});

$('.round-slider').slick({
    infinite: false,
    slidesToShow: 1,
    fade: true,
    cssEase: 'ease-in-out',
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
});

$('#markets').slick({
    infinite: false,
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 1650,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 1,
                centerMode: true

            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                centerMode: false
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                centerMode: true
            }
        },
        {
            breakpoint: 460,
            settings: {
                slidesToShow: 1,
                centerMode: false,
                dots: true
            }
        }
    ]

});

$('.partners').slick({
    infinite: true,
    slidesToShow: 6,
    dots: false,
    touchSensitivity: 3,
    speed: 500,
    arrows: false,
    dragToMove: true,
    slidesToScroll: 1,
    scrollingSpeed: 1000,
    rows: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false
            }

        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false
            },

        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false
            },

        }
    ]
});


window.onscroll = function () {
    myFunction()
};

// Get the navbar
var navbar = document.getElementById("navbar");

// Get the offset position of the navbar
var sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
    if (window.pageYOffset >= 60) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}

const loc = window.location.pathname;
const links = $('.links a');
for (i = 0; i < links.length; i++) {
    if (links[i].pathname === loc) {
        links[i].classList.add('nav-active');
    } else if (loc === '/' && i === 0) {
        links[i].classList.add('nav-active');
    } else {
        links[i].classList.remove('nav-active');
    }
}

const announce_btn = $('a.announcement_footer_buttons_items.detail');
const popup = $('#popup');
const wrapper = $('#black-wrapper');
const html = $('html');

const forgotPassBtn = $('.forgot_pass_btn');
const forgotPassPopup = $('#forgot-password__popup');

const getOfferBtn = $('a.get-offer__btn');
const getOfferPopUp = $('#get-offer__popup');

const marketDetailsBtn = $('a.market_details');
const marketDetailsPopUp = $('#markets__popup');

const mobileMenu = $('.hamburger-button');
const mobileMenuItems = $('.main');
const redWrapper = $('#red-wrapper');
const closeMenu = $('.stick-main');

const loginBtn = $('a#login_btn');
const HpLoginBtn = $('a.hp_login_btn');
const FpLoginBtn = $('a.fp_login_btn');
const loginPopup = $('#login__popup');

const clickMap = $('#mapDiv');
const infoArea = $('.contact-page__main');
const deactivateButton = $('.contact-page__main_icon');
const activateButton = $('.onclose-balloon');


deactivateButton.click(function () {
    infoArea.css('display', 'none');
});
activateButton.click(function () {
    infoArea.css('display', 'block')
});

getOfferBtn.click(function () {
    getOfferPopUp.css('display', 'flex');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
marketDetailsBtn.click(function () {
    marketDetailsPopUp.css('display', 'flex');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
loginBtn.click(function () {
    loginPopup.css('display', 'flex');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
forgotPassBtn.click(function () {
    loginPopup.css('display', 'none');
    forgotPassPopup.css('display', 'flex');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
HpLoginBtn.click(function () {
    loginPopup.css('display', 'flex');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
FpLoginBtn.click(function () {
    forgotPassPopup.css('display', 'none');
    loginPopup.css('display', 'flex');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
announce_btn.click(function () {
    popup.css('display', 'block');
    wrapper.css('display', 'block');
    html.css('overflow', 'hidden');
});
wrapper.click(function () {
    popup.css('display', 'none');
    wrapper.css('display', 'none');
    getOfferPopUp.css('display', 'none');
    loginPopup.css('display', 'none');
    forgotPassPopup.css('display', 'none');
    marketDetailsPopUp.css('display', 'none');
    html.css('overflow', 'visible');
});
mobileMenu.click(function () {
    redWrapper.css('display', 'block');
    mobileMenuItems.css('display', 'flex');
    html.css('overflow', 'hidden')
});
closeMenu.click(function () {
    redWrapper.css('display', 'none');
    mobileMenuItems.css('display', 'none');
    html.css('overflow', 'visible')
});
loginBtn.click(function () {
    redWrapper.css('display', 'none');
    mobileMenuItems.css('display', 'none');
    html.css('overflow', '  hidden')
});

window.initMap = function () {

    const homepagemap = $('.homepagemap').length;
    let center = homepagemap ? CODECK.map.center2 : CODECK.map.center1;
    var mapOptions = {
        zoom: 11,
        center: new google.maps.LatLng(center.lat, center.lng),

        styles: [{
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
        }, {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
        }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{"color": "#dedede"}, {"lightness": 21}]
        }, {
            "elementType": "labels.text.stroke",
            "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
        }, {
            "elementType": "labels.text.fill",
            "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
        }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
        }]
    };

    const mapElement = document.getElementById('map');

    const map = new google.maps.Map(mapElement, mapOptions);

    const marker = new google.maps.Marker({
        position: new google.maps.LatLng(CODECK.map.marker.lat, CODECK.map.marker.lng),
        map: map,
        title: 'Atakan Gıda'
    });
};
